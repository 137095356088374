<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-select
            outlined
            flat
            dense
            background-color="white"
            :items="listType"
            v-model="params.type"
            label="Type"
            @change="onChangeType"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="2">
          <v-btn color="primary" @click="search" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="download" class="mr-2" v-if="params.type <= 3">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="params.type === 5">
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" v-if="purchase">
          <v-file-input v-model="file" dense chips outlined flat placeholder="Upload here">
          </v-file-input>
        </v-col>
        <v-col cols="12" v-if="showResult">
          <v-data-table
            :headers="headerPurchase"
            :items="itemPurchase"
            :search="filterPurchase"
            hide-default-footer
            :items-per-page="-1"
            item-key="invoiceNumber"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="12" md="8" lg="8">
                  <v-text-field
                    flat
                    dense
                    outlined
                    background-color="white"
                    v-model="filterPurchase"
                    placeholder="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.invoiceAmount="{ item }">
              {{ formatPrice(item.invoiceAmount) }}
            </template>
            <template v-slot:item.ppnAmount="{ item }">
              {{ formatPrice(item.ppnAmount) }}
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="sale">
          <v-data-table
            v-model="selected"
            :headers="headerSale"
            :items="itemSale"
            show-select
            :search="filterSale"
            hide-default-footer
            :items-per-page="-1"
            item-key="invoiceNumber"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="12" md="8" lg="8">
                  <v-text-field
                    flat
                    dense
                    outlined
                    background-color="white"
                    v-model="filterSale"
                    placeholder="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.no="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.invoiceAmount="{ item }">
              {{ formatPrice(item.invoiceAmount) }}
            </template>
            <template v-slot:item.ppnAmount="{ item }">
              {{ formatPrice(item.ppnAmount) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="params.type === 5">
      <v-btn color="primary" @click="saveReportPpnKeluaran" v-if="sale">next</v-btn>
      <v-btn color="primary" @click="upload" v-if="purchase">next</v-btn>
      <v-btn color="primary" @click="report" v-if="showResult">Lapor</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import fileDownload from "js-file-download";
import moment from "moment";

export default {
  name: "ppn",
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      purchase: false,
      sale: false,
      showResult: false,
      params: {
        startDate: moment().format("yyyy-MM-DD"),
        endDate: moment().format("yyyy-MM-DD"),
        type: 0,
      },
      listType: [
        { value: 0, text: "PPN Keluaran" },
        { value: 1, text: "PPN Masukan" },
        { value: 2, text: "PPN Masukan Import" },
        { value: 3, text: "All" },
        { value: 5, text: "PPN" },
      ],
      itemPurchase: [],
      itemSale: [],
      headerPurchase: [
        {
          text: "No",
          align: "center",
          value: "no",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Nama",
          align: "left",
          value: "customerName",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NPWP",
          align: "left",
          value: "npwp",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NO SERI",
          align: "center",
          value: "taxSerialNumber",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "TGL",
          align: "center",
          value: "invoiceDate",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "DPP",
          align: "right",
          value: "invoiceAmount",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "PPN",
          align: "right",
          value: "ppnAmount",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "DP I",
          align: "center",
          value: "dpOne",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "DP II",
          align: "center",
          value: "dpTwo",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Pelunasan",
          align: "center",
          value: "payment",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      headerSale: [
        {
          text: "No",
          align: "center",
          value: "no",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Nama",
          align: "left",
          value: "customerName",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NPWP",
          align: "left",
          value: "npwp",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NO SERI",
          align: "center",
          value: "taxSerialNumber",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "TGL",
          align: "center",
          value: "invoiceDate",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "DPP",
          align: "right",
          value: "invoiceAmount",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "PPN",
          align: "right",
          value: "ppnAmount",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "NO INV",
          align: "center",
          value: "invoiceNumber",
          divider: true,
          sortable: false,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
      selected: [],
      file: null,
      filterPurchase: "",
      filterSale: "",
    };
  },
  methods: {
    async search() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/ppn", this.params)
        .then(response => {
          if (this.params.type === 5) {
            this.itemSale = response.data;
          }
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async download() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/ppnExcel", this.params)
        .then(response => {
          fileDownload(response.data, "ppn.xlsx");
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async upload() {
      this.$store.commit("SET_LOADER", true);
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("startDate", this.params.startDate);
      formData.append("endDate", this.params.endDate);
      await this.$store
        .dispatch("report/importPpn", formData)
        .then(response => {
          this.itemPurchase = response.data;
          this.showResult = true;
          this.purchase = false;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async saveReportPpnKeluaran() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/saveReportPpnKeluaran", this.selected)
        .then(() => {
          this.sale = false;
          this.purchase = true;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangeType(val) {
      if (val === 5) {
        this.sale = true;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    report() {
      this.params.type = 3;
      this.download();
    },
  },
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid grey;
}
</style>
